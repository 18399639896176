<style lang="less" scoped>
.detailBox {
    min-height: 500px;
    margin:0 auto 100px;
    background: rgba(5, 133, 197, 0.7);
    border-radius: 10px;
    box-shadow: 0 0 10px #000000;
    width: 65vw;
}
.detailTitle {
    margin:0;
    padding: 30px;
    text-align: center;
}
.imgWrap {
    padding: 0 30px;
    // border: solid #888 1px;
    >div {
        width: 32%;
        height: 19vw;
        // background: magenta;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        margin: 10px 0;
        float: left;
        margin-left: 1%;
        margin-bottom:0.25%;
        cursor: pointer;
    }
}
.detainMain {
    width: 100%;
    color: #fff !important;
    padding: 0 40px;
}
.width1000 {
    max-width: 65vw;
    margin: auto;
}
.newsImg {
    width: 45%;
    height: 35vw;
    float: left;
    overflow:hidden;
    margin-left: 3vw;
    margin-top: 3vw;
    >img {
        height: 100%;
        width: auto;
    }
}
.whiteColor2 {
    color: #fff !important;
    p,li,a,span {
        color: #fff !important;
    }
}
p,li,a,span {
    color: #fff !important;
}
</style>

<template>
    <div style="padding-top: 100px;">
        <div class="detailBox  newsBg whiteColor2">
            <h1 class="detailTitle">{{item.title}}</h1>
            <!-- PC -->
            <div class="mNone">
                <div class="imgWrap clearBoth" v-if="item.big_image.length > 0">
                    <div v-for="(img,i) in item.big_image" :key="i" 
                        @click="preview(i)"
                        :style="{background:`url(${img})`}"></div>
                </div>

                <div class="detainMain whiteColor2" style="flex: 10">
                    <div v-html="item.content" class="whiteColor2"></div>
                </div>
            </div><br>
            <!-- 移动 -->
            <div class="mShow">
                <div class="clearBoth mShow">
                    <div class="newsImg ju al" v-for="(img,i) in item.big_image" :key="i" @click="preview(i)">
                        <img :src="img" alt="">
                    </div>
                </div>
                <div class="detainMain">
                    <div v-html="item.content" class="whiteColor2"></div>
                </div>
            </div>
            


        </div>
        <br>
        <div class="width1000">
            <div class="sb">
               <div class="blueBtn" @click="back">
                    &lt; Back
                </div> 
            </div>
            
        </div>
        <div class="width1000">
            <br>
            <br>
            <br><br>
            <br><br>
            <br>
            <myFooter></myFooter>
        </div>

        <transition name="fade">
            <div v-if="showImgs">
                <div class="loopMask"></div>
                <div class="loopBox z10 ">
                    <el-carousel ref="loop" :autoplay="false" loop height="500px" class="relative z10" :initial-index="currentImg" @change="loopChange">
                        <el-carouselItem v-for="(item,i) in item.big_image" :key="i">
                            <div class="roopImg" :style="{background: `url('${item}') no-repeat`}"></div>
                        </el-carouselItem>
                    </el-carousel>
                    <div class="al miar20 sb">
                        <div class="al">
                            <div class="twoBtn al">
                                <div @click="preNext('prev')"></div>
                                <div @click="preNext('next')"></div>
                            </div>
                            <div style="margin-left: 20px">{{currentImg + 1}} / {{item.big_image.length}}</div>
                        </div>
                        <div class="close ju al" @click="showImgs=false">
                            <img src="@/assets/img/close.png" alt="">
                        </div>
                    </div>

                    <!-- <div class="loopNav">
                        <div style="width: 100%;height: 100%">
                            <myNav :list="item.big_image" :active="currentImg"></myNav>
                        </div>
                        
                    </div> -->
                </div>
            </div>
        </transition>
        
        
        
    </div>
</template>

<script>
export default {
    data () {
        return {
            uuid: "",
            item: {
                big_image: []
            } ,
            showImgs: false,
            currentImg: 0,
        }
    },
    created () {
        this.uuid = this.$route.query.uuid
        this.getData()
    },
    methods:{
        preNext (method) {
            this.$refs.loop[method]()
        },
        loopChange (e) {
            this.currentImg = e
        },
        //预览图片
		preview (i) {
            this.currentImg = i
            this.showImgs = true
			// this.$imagePreview({
			// 	images: [src],
			// 	index: 1,
			// })
		},
        back () {
            this.$router.back()
        },
        getData () {
            window.http({
                url: "news",
                params: {
                    uuid: this.uuid,
                }
            }).then(res => {
                console.log(res)
                if (res.data.code == 200) {
                    this.item = res.data.data
                    
                    if (!this.item.big_image) {
                        this.item.big_image = []
                    } else {
                        this.item.big_image = this.item.big_image.split(";")    
                    }
                    console.log(this.item.big_image)
                } else {
                    this.item = {
                        big_image: []
                    }
                }
            })
        }
    }
}
</script>

